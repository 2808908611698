import classNames from "classnames";

export function TopiaLoader(props: { className?: string;}) {
  return (
    <div className="flex justify-center items-center">
      <div
        className={classNames("spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full", props.className)}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export function FullScreenTopiaLoader(props: {}) {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center align-center bg-black/80 z-[1000]">
      <TopiaLoader className="text-white"/>
    </div>
  );
}
