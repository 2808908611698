import mixpanel from "mixpanel-browser";

export function trackAnalyticsEvent(eventName: string, properties?: any) {
  try {
    mixpanel.track(`webapp_${eventName}`, {
      ...properties,
    });
  } catch (ex) {
    console.error(ex);
  }
}
