import { useState } from "react";
import { useFloating, offset, shift, flip } from "@floating-ui/react";

export function TopiaTooltip(props: { className?: string; label: string }) {
  const [isOpen, setIsOpen] = useState(false);
  const { x, y, reference, floating, strategy } = useFloating({
    placement: "top",
    middleware: [offset(10), shift(), flip()],
  });
  // Created the tooltip detail and have it dynamically mapped based on each account!

  const tooltipDetail = [
    {
      id: "amount",
      title: "Amount",
      description: "What is the current balance of the account?",
    },
    {
      id: "amount",
      title: "Mortgage Amount",
      description:
        "What is the current value of the mortgage associated with the property?",
    },
    {
      id: "income-type",
      title: "Net Monthly Income",
      description:
        "What is the net monthly income of the salary or sidehustle?",
    },
    {
      id: "monthlyTopup",
      title: "Monthly Contribution",
      description:
        "On average how much do you contribute to this account each month?",
    },
    {
      id: "monthlyTopup",
      title: "Monthly Payment",
      description:
        "What is the total monthly payment you pay on your mortgage?",
    },
    {
      id: "annualReturn",
      title: "Annual Return",
      description:
        "What is the annual Growth you expect to achieve from this account?",
    },
    {
      id: "managementCharge",
      title: "Management Charge",
      description:
        "What ongoing fees and/or charges do you pay on this asset as a percentage of it's value?",
    },
    {
      id: "withdrawalRate",
      title: "Safe Withdrawal Rate",
      description:
        "How much can you sustainably withdraw from this account each year?",
    },
    {
      id: "interestRate",
      title: "Interest Rate",
      description: "What is the annual interest you pay on this debt?",
    },
    {
      id: "propertyValueOfProperty",
      title: "Property Value",
      description: "What is the value of the property?",
    },
    {
      id: "propertyAppreciationValue",
      title: "Forecasted appreciation rate",
      description:
        "How much do you expect this property to appreciate by this year?",
    },
    {
      id: "propertyMonthlyIncome",
      title: "Monthly Income",
      description:
        "If you generate any income from this property (renting the property or rooms) then input the monthly net income.",
    },
    {
      id: "",
      title: "FI Flavor",
      description: (
        <p className="text-black">
          Traditional FI- Build an investment portfolio large enough to support
          you once you FI.
          <br></br>
          <br></br>
          <strong>Barista FI </strong>- Work part-time while post FI to reduce
          your FI number and reach FI sooner.
          <br></br>
          <br></br>
          <strong>Coast FI </strong>- Not in a rush? Stop contributing to your
          investments and coast your way to FI. You just need to decide what age
          you want to reach full FI.
        </p>
      ),
    },
    {
      id: "",
      title: "Monthly Spend at FI",
      description:
        "When you retire/reach FI, how much do you anticipate you will spend on average every month?",
    },
    {
      id: "",
      title: "Current Monthly Spend",
      description: "On average how much do you spend each month?",
    },
    {
      id: "",
      title: "Retirement Income",
      description:
        "How much net income do you expect to make per month working part-time after you reach FI?",
    },
    {
      id: "propertyMonthlyIncome",
      title: "Monthly Income",
      description:
        "If you generate any income from this property (renting the property or rooms) then input the monthly net income.",
    },
    {
      id: "estimatedExpenditure",
      title: "Estimated Expenditure",
      description: "How much money do you plan on spending?",
    },
  ];

  const returnedText = tooltipDetail.filter((tip) => tip.title === props.label);

  return (
    <>
      {returnedText.length > 0 && (
        <div
          ref={reference}
          // onMouseEnter={() => {
          //   console.log(returnedText);
          // }}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          className={`relative flex items-center cursor-default justify-center text-xs align-center w-4 h-4 border border-gray-400 text-white rounded-full ${props.className}`}
        >
          ?
        </div>
      )}

      {isOpen && (
        <div
          ref={floating}
          className="p-2 topia-fade-in border border-topia-gray bg-white rounded-lg"
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: "max-content",
            maxWidth: 300,
          }}
        >
          <p className="text-center font-bold font-sans text-black text-lg">
            {returnedText.map((tip) => tip.title)}
          </p>
          <p className=" text-black">
            {returnedText.map((tip) => tip.description)}
          </p>
        </div>
      )}
    </>
  );
}
