// TODO: Move these somewhere else

export const ACCOUNT_TYPES = {
    SAVING: 'saving',
    DEBT: 'debt',
    STANDARD: 'standard',
    PROPERTY: 'property', // Only used for visual purposes
  } as const;
  export type ACCOUNT_TYPES = (typeof ACCOUNT_TYPES)[keyof typeof ACCOUNT_TYPES];
  
  const ACCOUNT_TYPES_LABELS = {
    SAVING: 'Savings & Investments',
    STANDARD: 'everyday spending',
  } as const;
  type ACCOUNT_TYPES_LABELS = (typeof ACCOUNT_TYPES_LABELS)[keyof typeof ACCOUNT_TYPES_LABELS];
  
  /**
   * Different Categories that an savings account can have
   */
  
  const ACCOUNT_CATEGORIES = {
    PENSION: 'pension',
    ISA: 'isa',
    OTHER: 'other',
    EMERGENCY_FUND: 'emergency_fund',
    MORTGAGE: 'mortgage',
    CREDIT_CART: 'credit_card',
    DEBT: 'debt',
    DEBT_OTHER: 'debt_other',
  } as const;
  type ACCOUNT_CATEGORIES = (typeof ACCOUNT_CATEGORIES)[keyof typeof ACCOUNT_CATEGORIES];
  
  const SAVING_CATEGORIES = {
    PENSION: 'pension',
    ISA: 'isa',
    OTHER: 'other',
    EMERGENCY_FUND: 'emergency_fund',
  } as const;
  type SAVING_CATEGORIES = (typeof SAVING_CATEGORIES)[keyof typeof SAVING_CATEGORIES];
  
  const DEBT_CATEGORIES = {
    MORTGAGE: 'mortgage',
    CREDIT_CART: 'credit_card',
    OTHER: 'debt_other',
  } as const;
  type DEBT_CATEGORIES = (typeof DEBT_CATEGORIES)[keyof typeof DEBT_CATEGORIES];
  
  const ACCOUNT_SUBTYPES = {
    EMPLOYER_PENSION: 'employer_pension',
    SELF_INVESTED_PENSION: 'self_invested_pension',
    DEFINED_BENEFITS_PENSION: 'defined_benefits_pension',
    STATE_PENSION: 'state_pension',
    // ISA
    STOCKS_SHARES_ISA: 'stocks_shares_isa',
    LISA: 'lisa',
    CASH_ISA: 'cash_isa',
    // OTHER
    OTHER: 'other',
    // Emergency func
    EMERGENCY_FUND: 'emergency_fund',
    SHORT_TERM_SAVINGS: 'short_term_saving',
    // DEBT
    DEBT: 'debt',
    MORTGAGE: 'mortgage',
    CREDIT_CART: 'credit_card',
    DEBT_OTHER: 'debt_other',
    // BESPOKE
    PROPERTY_VALUATION: 'property_valuation',
    // CASH
    CASH: 'cash',
  } as const;
  type ACCOUNT_SUBTYPES = (typeof ACCOUNT_SUBTYPES)[keyof typeof ACCOUNT_SUBTYPES];
  
  export const ACCOUNT_TAX_STATUSES = {
    TAX_FREE_CONTRIBUTION: 'tax_free_contribution',
    TAX_FREE_WITHDRAWAL: 'tax_free_withdrawal',
    TAXED_OTHER: 'taxed_other',
  } as const;
  type ACCOUNT_TAX_STATUSES = (typeof ACCOUNT_TAX_STATUSES)[keyof typeof ACCOUNT_TAX_STATUSES];
  
  /**
   * Portfolio transfer options
   */
  
  const PORTFOLIO_TRANSFER_OPTIONS = {
    SELL: 'sell',
    BUY: 'buy',
    EQUITY_RELEASE: 'equity_release',
  } as const;
  type PORTFOLIO_TRANSFER_OPTIONS = (typeof PORTFOLIO_TRANSFER_OPTIONS)[keyof typeof PORTFOLIO_TRANSFER_OPTIONS];
  
  /**
   * Different subtypes that an savings account can have
   */
  
  const SAVING_SUBTYPES = {
    // Pension
    EMPLOYER_PENSION: 'employer_pension',
    SELF_INVESTED_PENSION: 'self_invested_pension',
    DEFINED_BENEFITS_PENSION: 'defined_benefits_pension',
    STATE_PENSION: 'state_pension',
    // ISA
    STOCKS_SHARES_ISA: 'stocks_shares_isa',
    LISA: 'lisa',
    CASH_ISA: 'cash_isa',
    // OTHER
    OTHER: 'other',
    // EMERGENCY
    EMERGENCY_FUND: 'emergency_fund',
    SHORT_TERM_SAVINGS: 'short_term_saving',
  
    // RANDOM
    CASH: 'cash',
  } as const;
  type SAVING_SUBTYPES = (typeof SAVING_SUBTYPES)[keyof typeof SAVING_SUBTYPES];
  
  const SAVING_SUBTYPE_NAMES = {
    // Pension
    [SAVING_SUBTYPES.EMPLOYER_PENSION]: 'savings.accounts.employerPension',
    [SAVING_SUBTYPES.SELF_INVESTED_PENSION]: 'savings.accounts.selfInvestedPension',
    [SAVING_SUBTYPES.DEFINED_BENEFITS_PENSION]: 'savings.accounts.definedBenefitsPension',
    [SAVING_SUBTYPES.STATE_PENSION]: 'savings.accounts.statePension',
    // ISA
    [SAVING_SUBTYPES.STOCKS_SHARES_ISA]: 'savings.accounts.stocksShareIsa',
    [SAVING_SUBTYPES.LISA]: 'savings.accounts.lisa',
    [SAVING_SUBTYPES.CASH_ISA]: 'savings.accounts.cashIsa',
    // OTHER
    [SAVING_SUBTYPES.OTHER]: 'savings.accounts.other',
    // EMERGENCY
    [SAVING_SUBTYPES.EMERGENCY_FUND]: 'savings.accounts.emergencyFund',
    [SAVING_SUBTYPES.SHORT_TERM_SAVINGS]: 'savings.accounts.shortTermSavings',
    // RANDOM
    [SAVING_SUBTYPES.CASH]: 'savings.accounts.cash',
  } as const;
  type SAVING_SUBTYPE_NAMES = (typeof SAVING_SUBTYPE_NAMES)[keyof typeof SAVING_SUBTYPE_NAMES];
  
  type SAVING_GROUPED_SUBTYPES_TYPE = {
    [key in ACCOUNT_CATEGORIES]?: {
      label: string;
      value: ACCOUNT_SUBTYPES;
    }[];
  };
  
  const SAVING_GROUPED_SUBTYPES: SAVING_GROUPED_SUBTYPES_TYPE = {
    [ACCOUNT_CATEGORIES.PENSION]: [
      // Pension
      {
        label: 'savings.accounts.employerPension',
        value: ACCOUNT_SUBTYPES.EMPLOYER_PENSION,
      },
      {
        label: 'savings.accounts.selfInvestedPension',
        value: ACCOUNT_SUBTYPES.SELF_INVESTED_PENSION,
      },
      {
        label: 'savings.accounts.definedBenefitsPension',
        value: ACCOUNT_SUBTYPES.DEFINED_BENEFITS_PENSION,
      },
      { label: 'savings.accounts.statePension', value: ACCOUNT_SUBTYPES.STATE_PENSION },
    ],
    [ACCOUNT_CATEGORIES.ISA]: [
      // ISA
      {
        label: 'savings.accounts.stocksShareIsa',
        value: ACCOUNT_SUBTYPES.STOCKS_SHARES_ISA,
      },
    ],
    [ACCOUNT_CATEGORIES.OTHER]: [
      // Other
      { label: 'savings.accounts.other', value: ACCOUNT_SUBTYPES.OTHER },
      { label: 'savings.accounts.cash', value: ACCOUNT_SUBTYPES.CASH },
    ],
    [ACCOUNT_CATEGORIES.EMERGENCY_FUND]: [
      // Emergency Fund
      { label: 'savings.accounts.emergencyFund', value: ACCOUNT_SUBTYPES.EMERGENCY_FUND },
      {
        label: 'savings.accounts.shortTermSavings',
        value: ACCOUNT_SUBTYPES.SHORT_TERM_SAVINGS,
      },
    ],
    [ACCOUNT_CATEGORIES.DEBT]: [
      // Debt
      { label: 'debts.tabs.other', value: ACCOUNT_SUBTYPES.DEBT_OTHER },
    ],
    [ACCOUNT_CATEGORIES.DEBT_OTHER]: [
      // Debt Other
      { label: 'debts.tabs.other', value: ACCOUNT_SUBTYPES.DEBT_OTHER },
    ],
    [ACCOUNT_CATEGORIES.MORTGAGE]: [
      // Debt Mortgage
      { label: 'debts.tabs.creditCart', value: ACCOUNT_SUBTYPES.MORTGAGE },
    ],
    [ACCOUNT_CATEGORIES.CREDIT_CART]: [
      // Debt Credit Card
      { label: 'debts.tabs.mortgage', value: ACCOUNT_SUBTYPES.CREDIT_CART },
    ],
    [ACCOUNT_CATEGORIES.CREDIT_CART]: [
      // Debt Credit Card
      { label: 'debts.tabs.mortgage', value: ACCOUNT_SUBTYPES.CREDIT_CART },
    ],
  };
  
  const SAVING_GROUPED_SUBTYPES_US = {
    ...SAVING_GROUPED_SUBTYPES,
    [ACCOUNT_CATEGORIES.ISA]: [
      // ISA
      {
        label: 'savings.accounts.stocksShareIsa',
        value: ACCOUNT_SUBTYPES.STOCKS_SHARES_ISA,
      },
  
      // { label: 'savings.accounts.lisa', value: ACCOUNT_SUBTYPES.LISA },
      { label: 'savings.accounts.cashIsa', value: ACCOUNT_SUBTYPES.CASH_ISA },
    ],
  };
  
  // TODO: @typescript-fixes-review
  // const CONTRIBUTION_MAP: Record<string, {
  //     contributionProperties: {
  //         property:string,
  //         type:string,
  //         unknownProperty?:string,
  //         defaultValue?: any;
  //         manualProperty?:string,
  //         popupText:string,
  //         uncheckedValues?: any;
  //     }[]
  // }> = {
  
  export type ContributionProperties = {
    property: string;
    type: 'relative' | 'absolute' | 'boolean';
    unknownProperty?: string;
    manualProperty?: string;
    popupText?:  string;
    usedForFINumber?: boolean;
    defaultValue?: any;
    uncheckedValues?: {
      minTopup?: number,
      monthlyTopup?: number,
    },
  }
  const CONTRIBUTION_MAP: {
    [K in SAVING_SUBTYPES | ACCOUNT_TYPES | ACCOUNT_SUBTYPES]?: {
      contributionProperties: ContributionProperties[];
    }
  }  = {
    [SAVING_SUBTYPES.EMPLOYER_PENSION]: {
      // contributionProperty: 'maxExtraContribution',
      contributionProperties: [
        // {
        //   property: 'maxExtraContribution',
        //   type: 'relative',
        //   unknownProperty: 'maxExtraContributionUnverified',
        //   popupText: 'maxExtraContributionHelp',
        // },
        {
          property: 'monthlyTopup',
          type: 'absolute',
          popupText: 'monthlyTopupHelp',
          usedForFINumber: true,
        },
      ],
    },
    [ACCOUNT_TYPES.DEBT]: {
      // contributionProperty: 'minTopup',
      contributionProperties: [
        {
          property: 'minTopup',
          type: 'absolute',
          unknownProperty: 'minTopupUnverified',
          popupText: 'minTopupHelp',
        },
        {
          property: 'monthlyTopup',
          manualProperty: 'manualMonthlyTopup',
          popupText: 'monthlyTopupHelp',
          type: 'absolute',
          usedForFINumber: true,
        },
      ],
    },
    [ACCOUNT_SUBTYPES.CREDIT_CART]: {
      // contributionProperty: 'minTopup',
      contributionProperties: [
        {
          property: 'paidOffEachMonth',
          type: 'boolean',
          defaultValue: false,
          uncheckedValues: {
            // minTopup: 0,
            monthlyTopup: 0,
          },
        },
        // {
        //   property: 'minTopup',
        //   type: 'absolute',
        //   unknownProperty: 'minTopupUnverified',
        //   popupText: 'minTopupHelp',
        // },
        {
          property: 'monthlyTopup',
          manualProperty: 'manualMonthlyTopup',
          popupText: 'monthlyTopupHelp',
          type: 'absolute',
          usedForFINumber: true,
        },
      ],
    },
    [ACCOUNT_SUBTYPES.MORTGAGE]: {
      // contributionProperty: 'minTopup',
      contributionProperties: [
        // {
        //   property: 'minTopup',
        //   type: 'absolute',
        //   unknownProperty: 'minTopupUnverified',
        //   popupText: 'minTopupHelp',
        // },
        {
          property: 'monthlyTopup',
          manualProperty: 'manualMonthlyTopup',
          popupText: 'monthlyTopupHelp',
          type: 'absolute',
          usedForFINumber: true,
        },
        {
          property: 'property.valueOfProperty',
          // manualProperty: 'valueOfProperty',
          // popupText: 'valueOfProperty',
          type: 'absolute',
          defaultValue: 0,
        },
        {
          property: 'property.includeHomeEquity',
          // manualProperty: 'includeHomeEquity',
          // popupText: 'includeHomeEquity',
          type: 'boolean',
          defaultValue: true,
        },
      ],
    },
    [ACCOUNT_TYPES.SAVING]: {
      // contributionProperty: 'minTopup',
      contributionProperties: [
        {
          property: 'monthlyTopup',
          manualProperty: 'manualMonthlyTopup',
          type: 'absolute',
          popupText: 'monthlyTopupHelp',
          usedForFINumber: true,
        },
      ],
    },
  };
  
  /**
   * Tabs
   */
  
  // TODO: should we define all the keys individually for the 2 tab types?
  export type CategoryTabs = {
    [key in ACCOUNT_CATEGORIES]?: {
      name: string;
      value: ACCOUNT_CATEGORIES;
      empty: string;
    };
  };
  
  const SAVING_TABS: CategoryTabs = {
    [ACCOUNT_CATEGORIES.PENSION]: {
      name: 'savings.tabs.pension',
      value: ACCOUNT_CATEGORIES.PENSION,
      empty: 'savings.tabs.pensionEmpty',
    },
    [ACCOUNT_CATEGORIES.ISA]: {
      name: 'savings.tabs.isa',
      value: ACCOUNT_CATEGORIES.ISA,
      empty: 'savings.tabs.isaEmpty',
    },
    [ACCOUNT_CATEGORIES.OTHER]: {
      name: 'savings.tabs.other',
      value: ACCOUNT_CATEGORIES.OTHER,
      empty: 'savings.tabs.otherEmpty',
    },
    [ACCOUNT_CATEGORIES.EMERGENCY_FUND]: {
      name: 'savings.tabs.emergency',
      value: ACCOUNT_CATEGORIES.EMERGENCY_FUND,
      empty: 'savings.tabs.emergencyEmpty',
    },
  };
  
  const DEBT_TABS: CategoryTabs = {
    [ACCOUNT_CATEGORIES.MORTGAGE]: {
      name: 'debts.tabs.mortgage',
      value: ACCOUNT_CATEGORIES.MORTGAGE,
      empty: 'No Mortgages to show',
    },
    [ACCOUNT_CATEGORIES.CREDIT_CART]: {
      name: 'debts.tabs.creditCart',
      value: ACCOUNT_CATEGORIES.CREDIT_CART,
      empty: 'No Credit Cards to show',
    },
    [ACCOUNT_CATEGORIES.DEBT_OTHER]: {
      name: 'debts.tabs.other',
      value: ACCOUNT_CATEGORIES.DEBT_OTHER,
      empty: 'No Other Accounts to show',
    },
  };
  
  const PORTFOLIO_TRANSFER_TABS = {
    [PORTFOLIO_TRANSFER_OPTIONS.SELL]: {
      name: 'portfolioTransferOptions.sell',
      value: PORTFOLIO_TRANSFER_OPTIONS.SELL,
    },
    [PORTFOLIO_TRANSFER_OPTIONS.BUY]: {
      name: 'portfolioTransferOptions.buy',
      value: PORTFOLIO_TRANSFER_OPTIONS.BUY,
    },
    [PORTFOLIO_TRANSFER_OPTIONS.EQUITY_RELEASE]: {
      name: 'portfolioTransferOptions.equityRelease',
      value: PORTFOLIO_TRANSFER_OPTIONS.EQUITY_RELEASE,
    },
  };
  
  const CATEGORY_TABS: CategoryTabs = { ...SAVING_TABS, ...DEBT_TABS };
  
  /**
   * CATEGORY OPTIONS
   */
  
  // TODO: should we define all the keys individually for the 2 tab types?
  type TypeOption = {
    label: string;
    value: ACCOUNT_CATEGORIES | ACCOUNT_SUBTYPES;
    category: ACCOUNT_CATEGORIES;
    taxStatus: ACCOUNT_TAX_STATUSES;
  };
  
  const SAVING_OPTIONS: TypeOption[] = [
    {
      label: 'Employers Pension',
      value: ACCOUNT_SUBTYPES.EMPLOYER_PENSION,
      category: ACCOUNT_CATEGORIES.PENSION,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_CONTRIBUTION,
    },
    {
      label: 'Self Invested Pension (SIPP)',
      value: ACCOUNT_SUBTYPES.SELF_INVESTED_PENSION,
      category: ACCOUNT_CATEGORIES.PENSION,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_CONTRIBUTION,
    },
    {
      label: 'Defined Benefits Pension',
      value: ACCOUNT_SUBTYPES.DEFINED_BENEFITS_PENSION,
      category: ACCOUNT_CATEGORIES.PENSION,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_CONTRIBUTION,
    },
    {
      label: 'State Pension',
      value: ACCOUNT_SUBTYPES.STATE_PENSION,
      category: ACCOUNT_CATEGORIES.PENSION,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_CONTRIBUTION,
    },
    {
      label: 'Stocks & Shares ISA',
      value: ACCOUNT_SUBTYPES.STOCKS_SHARES_ISA,
      category: ACCOUNT_CATEGORIES.ISA,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_WITHDRAWAL,
    },
    {
      label: 'LISA',
      value: ACCOUNT_SUBTYPES.LISA,
      category: ACCOUNT_CATEGORIES.ISA,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_WITHDRAWAL,
    },
    {
      label: 'Cash ISA',
      value: ACCOUNT_SUBTYPES.CASH_ISA,
      category: ACCOUNT_CATEGORIES.ISA,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_WITHDRAWAL,
    },
    {
      label: 'Other',
      value: ACCOUNT_SUBTYPES.OTHER,
      category: ACCOUNT_CATEGORIES.OTHER,
      taxStatus: ACCOUNT_TAX_STATUSES.TAXED_OTHER,
    },
    {
      label: 'Emergency Fund',
      value: ACCOUNT_SUBTYPES.EMERGENCY_FUND,
      category: ACCOUNT_CATEGORIES.EMERGENCY_FUND,
      taxStatus: ACCOUNT_TAX_STATUSES.TAXED_OTHER,
    },
  ];
  
  const SAVING_OPTIONS_US: TypeOption[] = [
    {
      label: '401k',
      value: ACCOUNT_SUBTYPES.EMPLOYER_PENSION,
      category: ACCOUNT_CATEGORIES.PENSION,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_CONTRIBUTION,
    },
    {
      label: 'Roth 401k',
      value: ACCOUNT_SUBTYPES.SELF_INVESTED_PENSION,
      category: ACCOUNT_CATEGORIES.PENSION,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_CONTRIBUTION,
    },
    {
      label: 'Pension',
      value: ACCOUNT_SUBTYPES.DEFINED_BENEFITS_PENSION,
      category: ACCOUNT_CATEGORIES.PENSION,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_CONTRIBUTION,
    },
    {
      label: 'Social Security',
      value: ACCOUNT_SUBTYPES.STATE_PENSION,
      category: ACCOUNT_CATEGORIES.PENSION,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_CONTRIBUTION,
    },
    {
      label: 'Roth',
      value: ACCOUNT_SUBTYPES.STOCKS_SHARES_ISA,
      category: ACCOUNT_CATEGORIES.ISA,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_WITHDRAWAL,
    },
    // {
    //   label: 'savings.accounts.lisa',
    //   value: ACCOUNT_SUBTYPES.LISA,
    //   category: ACCOUNT_CATEGORIES.ISA,
    //   taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_WITHDRAWAL,
    // },
    {
      label: 'Traditional',
      value: ACCOUNT_SUBTYPES.CASH_ISA,
      category: ACCOUNT_CATEGORIES.ISA,
      taxStatus: ACCOUNT_TAX_STATUSES.TAX_FREE_WITHDRAWAL,
    },
    {
      label: 'Other',
      value: ACCOUNT_SUBTYPES.OTHER,
      category: ACCOUNT_CATEGORIES.OTHER,
      taxStatus: ACCOUNT_TAX_STATUSES.TAXED_OTHER,
    },
    {
      label: 'Emergency Fund',
      value: ACCOUNT_SUBTYPES.EMERGENCY_FUND,
      category: ACCOUNT_CATEGORIES.EMERGENCY_FUND,
      taxStatus: ACCOUNT_TAX_STATUSES.TAXED_OTHER,
    },
  ];
  
  type CategoryOption = {
    label: string;
    value: ACCOUNT_CATEGORIES | ACCOUNT_SUBTYPES;
    category?: ACCOUNT_CATEGORIES
  };
  
  const DEBT_OPTIONS: CategoryOption[] = [
    {
      label: 'Mortgage',
      value: ACCOUNT_CATEGORIES.MORTGAGE,
    },
    {
      label: 'Credit Card',
      value: ACCOUNT_CATEGORIES.CREDIT_CART,
      category: ACCOUNT_CATEGORIES.CREDIT_CART,
    },
    {
      label: 'Other',
      value: ACCOUNT_CATEGORIES.DEBT_OTHER,
      category: ACCOUNT_CATEGORIES.DEBT_OTHER,
    },
  ];
  
  type StatusOption = {
    label: string;
    value: ACCOUNT_TAX_STATUSES;
  };
  
  const TAX_STATUSES: StatusOption[] = [
    {
      label: 'Tax free contributions',
      value: ACCOUNT_TAX_STATUSES.TAX_FREE_CONTRIBUTION,
    },
    {
      label: 'Tax free withdrawals',
      value: ACCOUNT_TAX_STATUSES.TAX_FREE_WITHDRAWAL,
    },
    {
      label: 'Taxed (other)',
      value: ACCOUNT_TAX_STATUSES.TAXED_OTHER,
    },
  ];
  
  export {
    ACCOUNT_TYPES_LABELS,
    ACCOUNT_CATEGORIES,
    SAVING_CATEGORIES,
    SAVING_SUBTYPES,
    PORTFOLIO_TRANSFER_OPTIONS,
    SAVING_GROUPED_SUBTYPES_US,
    SAVING_GROUPED_SUBTYPES,
    SAVING_SUBTYPE_NAMES,
    DEBT_CATEGORIES,
    CONTRIBUTION_MAP,
    ACCOUNT_SUBTYPES,
    SAVING_TABS,
    DEBT_TABS,
    SAVING_OPTIONS,
    SAVING_OPTIONS_US,
    DEBT_OPTIONS,
    TAX_STATUSES,
    PORTFOLIO_TRANSFER_TABS,
  };
  