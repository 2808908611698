export function IntercomIllustration(props: {
  className?: string;
  svgProps?: any;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="58"
      fill="none"
      viewBox="0 0 58 58"
      className={props.className}
      {...props.svgProps}
    >
      <circle cx="29" cy="29" r="28.5" fill="#FDFFF9" stroke="#1D1E20" />
      <path
        fill="#1D1E20"
        d="M42 17v28.5L34.5 42H18a3 3 0 0 1-3-3V17a3 3 0 0 1 3-3h21a3 3 0 0 1 3 3Z"
      />
      <path
        stroke="#FDFFF9"
        stroke-linecap="round"
        stroke-width="1.25"
        d="M19 34c1.167 1 4.7 3 9.5 3s8-2 9-3"
      />
    </svg>
  );
}
